export function Underlay() {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        minHeight: 400,
        padding: 0,
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        pointerEvents: "none",
      }}>
      <div style={{ width: "100%", padding: 0, display: "inline-flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
        <p
          style={{
            fontFamily: "'DM Sans', sans-serif",
            flex: "1 1 0%",
            height: 30,
            fontSize: 12,
            fontWeight: "400",
            lineHeight: "30px",
            color: "black",
            letterSpacing: 0,
          }}>
         
        </p>
        <div style={{ flex: "1 1 0%", display: "flex", gap: "2em" }}></div>
        <p style={{ whiteSpace: "nowrap", flex: "1 1 0%", fontSize: "1.777rem", color: "black", textAlign: "left", paddingTop: 20, paddingRight: 40 }}><span class="infohead">Ilhan Zulji</span>
          <br />
          Visuelle Kommunikation</p>
      </div>
      
      <div
        style={{
          fontFamily: "'DM Sans', sans-serif",
          fontWeight: "500",
          width: "100%",
          maxWidth: "2560px",
          bottom: "0",
          position: "absolute",
          paddingRight: 40,
          paddingBottom: 20,
          paddingLeft: 40,
        }}>
        <p style={{ flex: "1 1 0%", fontSize: "11vw", lineHeight: "1em", color: "black", letterSpacing: 0 }}>Grafik,<br /> Interaktion &<br /> Druck</p>
        <div style={{ width: 10 }} />
      
      </div>
      <div style={{ height: 60 }} />
      <div
        style={{
          pointerEvents: "all",
          pointer: "auto",
          width: "100%",
          padding: 0,
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "center",
        }}>

        <div style={{ width: 10 }} />
        <p
          className="full"
          style={{
            fontFamily: "'DM Sans', sans-serif",
            flex: "1 1 0%",
            fontSize: 16,
            fontWeight: "700",
            lineHeight: "1em",
            textAlign: "center",
            color: "black",
            letterSpacing: -0.5,
            whiteSpace: "nowrap",
          }}>
          
        </p>
        <div style={{ width: 10 }} />

      </div>
    </div>
  )
}

export function Overlay() {
}
